/**
 * news.jsx
 * お知らせ一覧ページ
 */

// (1) import層
import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { Layout, FltShopBtn, Pagenation } from "../components/index"//サイト全体のコンポーネント
import { LowerHeader, LowerHeaderBg } from "../components/LowerPages/index"
// import styled from "styled-components"
import "../styles/LowerPage.scss";
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Img from "gatsby-image"
import { CategoryMenu } from '../components/index'
import "../styles/NewsList.scss"
// (2) Types層

// (3) DOM層
const News = ({ data, pageContext }) => {
  const posts = data.allWpPost.edges;
  console.log(pageContext);
  const title1 = [
    { "id": 0, "text": "n" },
    { "id": 1, "text": "e" },
    { "id": 2, "text": "w" },
    { "id": 3, "text": "s" }
  ];
  const breakpoints = useBreakpoint();
  return (
    <>
      <Layout className={`news`} pageName="news" title="お知らせ">
        <Seo
          lang="ja"
          title={`お知らせ`}
          description="作成中"
        />
        <div className={`LowerHeadImg`}>
          {
            breakpoints.sm ?
              <StaticImage
                layout="fullWidth"
                src="../images/news/news_header-sp.png"
                alt="news_header"
              />
              :
              <StaticImage
                layout="fullWidth"
                src="../images/news/news_header.png"
                alt="news_header"
              />

          }
        </div>
        <div className={`LowerWrapper`}>
          <LowerHeader data={title1} title={`お知らせ`}>
            <LowerHeaderBg />
          </LowerHeader>
          <div className={`LowerContent`}>
            <div className={`RadiusCircle`} ></div>
            <div className={`LowerContent__inner`}>
              <CategoryMenu />
              <ul className={`categoryList`}>
                {posts.map(({ node }) => (
                  <li key={node.slug}>
                    <a href={node.link}>
                      <Img
                        fluid={node.featuredImage.node.localFile.childImageSharp.fluid}
                        alt={node.title}
                      />
                      <div className={`listInfo`}>
                        <span className={`listCat ` + node.categories.nodes[0].slug}>{node.categories.nodes[0].name}</span>
                        <span className={`listDate`}>{node.date}</span>
                      </div>
                      <h3 className={`listTitle`}>
                        {node.title}
                      </h3>
                    </a>
                  </li>
                ))}
              </ul>
              <Pagenation pageContext={pageContext} />
            </div>
          </div>
        </div>
        <FltShopBtn />
      </Layout>
    </>
  )
}

export default News

// (4) Style層

// (5) Container層
export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!){
    allWpPost(
      sort: { fields: [date], order: DESC }
      skip: $skip 
      limit: $limit 
    ) {
      totalCount
      edges {
        node {
          categories{
            nodes{
              slug
              link
              name
            }
          }
          date(formatString: "YYYY.MM.DD")
          slug
          title
          id
          link
          featuredImage {
            node {
              localFile {
                url
                childImageSharp {
                 fluid {
                   ...GatsbyImageSharpFluid_withWebp_tracedSVG
                 }
                }
              }
            }
          }
        }
      }
    }
  }
`